import React, { Component } from "react";
import { Follow } from "react-twitter-widgets";
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/profilepic.jpeg" alt="" />
          </div>

          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>{resumeData.aboutme}</p>

            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{resumeData.name}</span>,&nbsp;
                  <span>{resumeData.address}</span>
                  <br></br>
                  <span>
                    <a href={resumeData.website}>{resumeData.website}</a>
                  </span>
                </p>
              </div>
            </div>
            <div className="about">
              <div className="">
                <Follow
                  username={resumeData.twitter}
                  options={{ size: "large" }}
                />
              </div>
              <div className="">
                <a href="https://stackoverflow.com/users/4319438/santosh-shinde">
                  <img
                    src="https://stackoverflow.com/users/flair/4319438.png"
                    alt=""
                  />
                </a>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
