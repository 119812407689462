import React, { Component } from "react";
import Axios from "axios";
import ShowBlog from "./blogs/ShowBlog";

export class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        ptitle: "",
        name: "",
        avtar: "",
        profileurl: ""
      },
      item: [],
      isloading: true,
      error: null
    };
  }
  mediumURL =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@santosh-shinde";

  componentDidMount() {
    Axios.get(this.mediumURL)

      .then(data => {
        const avatar = data.data.feed.image;
        const profileLink = data.data.feed.link;
        const res = data.data.items;
        const posts = res.filter(item => item.categories.length > 0);

        const title = data.data.feed.title;

        this.setState(
          pre => ({
            profile: {
              ...pre.profile,
              ptitle: title,
              profileurl: profileLink,
              avtar: avatar
            },
            item: posts,
            isloading: false
          }),
          () => {}
        );
      })
      .catch(e => {
        //console.log(e);
      });
  }
  render() {
    const { item } = this.state;

    return (
      <section id="posts">
        <div className="band">
          {item &&
            item.map((post, index) => (
              <div className={`item-${index + 1}`} key={`item-${index + 1}`}>
                <ShowBlog
                  key={index}
                  {...post}
                  {...this.state.profile}
                  {...index}
                />
              </div>
            ))}
        </div>
      </section>
    );
  }
}

export default Blog;
