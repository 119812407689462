import React from "react";
import ToText from "./ToText";
import {
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  LinkedinIcon
} from "react-share";

const getImage = (description) => {
  let DOMParsing = new DOMParser()
  console.log('description', description);
  let parsed = DOMParsing.parseFromString(description, "text/html")
  let imgURL = parsed.querySelector("img").src
  console.log('imgURL', imgURL);
  return imgURL
};

function ShowBlog(props, p) {
  const link = props.link
    ? props.link.split("?")[0]
    : "http://blog.santoshshinde.com";
  return (
    <div className="card">
      <a
        className="thumb"
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{ backgroundImage: `url(${getImage(props.description)})` }}
      >{``}</a>
      <article>
        <h1>
          <a href={link} target="_blank" rel="noreferrer">
            {props.title}
          </a>
        </h1>
        <p className="cardText">
          {`${ToText(props.description.substring(0, 250))}...`}
        </p>
        <div className="post-footer">
          <div className="author">
            <img src={props.avtar} alt="Avatar" className="avatar"></img>
            <div className="info">
              <div>{props.author}</div>
              <div>{new Date(Date.parse(props.pubDate)).toDateString()}</div>
            </div>
          </div>
          <div className="share">
            <div>
              <TwitterShareButton title={props.title} url={link}>
                <TwitterIcon size="2.5rem" />
              </TwitterShareButton>
            </div>
            <div>
              <LinkedinShareButton
                title={props.title}
                url={link}
                windowWidth={750}
                windowHeight={600}
              >
                <LinkedinIcon size="2.5rem" />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default ShowBlog;
