import React, { Component } from "react";
import { Timeline } from "react-twitter-widgets";

export default class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Education</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            {resumeData.education &&
              resumeData.education.map((item, index) => {
                return (
                  <div className="row item" key={`item-${index}`}>
                    <div className="twelve columns">
                      <h3>{item.UniversityName}</h3>
                      <p className="info">
                        {item.Specialization}
                        <span>&bull;</span>{" "}
                        <em className="date">
                          {item.MonthOfPassing} {item.YearOfPassing}
                        </em>
                      </p>
                      <p>{item.Description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            {resumeData.work &&
              resumeData.work.map((item, index) => {
                return (
                  <div className="row item" key={`item-${index}`}>
                    <div className="twelve columns">
                      <h3>{item.CompanyName}</h3>
                      <p className="info">{item.Specialization}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="github-stats">
              <a
                target="_blank"
                href={`https://github.com/${resumeData.github}`}
                rel="noreferrer"
              >
                <img
                  src={`https://github-readme-stats.vercel.app/api/top-langs/?username=${resumeData.github}&langs_count=4`}
                  alt=""
                />
              </a>
              <a
                target="_blank"
                href={`https://github.com/${resumeData.github}`}
                rel="noreferrer"
              >
                <img
                  align="center"
                  src={`https://github-readme-stats.vercel.app/api?username=${resumeData.github}&count_private=true`}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="row tweets">
          <div className="three columns header-col">
            <h1>
              <span>Latest Tweets</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            {
              <Timeline
                dataSource={{
                  sourceType: "url",
                  screenName: resumeData.twitter
                }}
                options={{
                  chrome: "noheader, nofooter",
                  height: "350",
                  dnt: true
                }}
              />
            }
          </div>
        </div>
      </section>
    );
  }
}
