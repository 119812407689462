let resumeData = {
  imagebaseurl: "http://blog.santoshshinde.com/",
  name: "Santosh Shinde",
  role:
    "Full Stack Developer | Lead Software Engineer | Micro Services | Node JS | ReactJS | Angular | Typescript | JavaScript 🇮🇳",
  linkedinId: "https://www.linkedin.com/in/santosh-shinde-54454635",
  roleDescription: "",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/santosh-shinde-54454635",
      className: "fa fa-linkedin"
    },
    {
      name: "github",
      url: "https://github.com/santoshshinde2012",
      className: "fa fa-github"
    },
    {
      name: "twitter",
      url: "https://twitter.com/shindesan2012",
      className: "fa fa-twitter"
    },
    {
      name: "stack overflow",
      url: "https://stackoverflow.com/users/4319438/santosh-shinde",
      className: "fa fa-stack-exchange"
    },
    {
      name: "medium",
      url: "https://medium.com/@santosh-shinde",
      className: "fa fa-book"
    }
  ],
  aboutme: "With over 10+ years of experience as a full stack engineer, I specialize in developing and deploying cloud-native web applications using JavaScript, React, Node, and AWS. I have a strong background in education admissions domain, where I provide functional and technical solutions as the Lead Software Engineer at Ellucian, a leading provider of software and services for higher education institutions. As a passionate and curious learner, I constantly explore new technologies and languages to enhance my skills and deliver high-quality products. I also share my knowledge and insights through my blog and portfolio, where I showcase my projects and publications. Some of the technologies and tools that I use include Angular, TypeScript, Next, Nest, MongoDB, PostgreSQL, GraphQL, Socket.io, and Microservices Architecture.",
  address: "India",
  github: "santoshshinde2012",
  twitter: "shindesan2012",
  website: "http://blog.santoshshinde.com",
  education: [
    {
      UniversityName: "University of Pune",
      Specialization: "Computer Engineering",
      MonthOfPassing: "Jun",
      YearOfPassing: "2014",
      Description:
        "Completed Three Year Graduation With First Class With Distinction"
    },
    {
      UniversityName: "University of Mumbai",
      Specialization: "Computer Technologies",
      MonthOfPassing: "May",
      YearOfPassing: "2011",
      Description:
        "Completed Three Year Diploma With First Class With Distinction"
    }
  ],
  gallery: [
    {
      title: "Image1",
      image: "images/portfolio/image1.jpeg"
    },
    {
      title: "Image2",
      image: "images/portfolio/image2.jpeg"
    },
    {
      title: "Image6",
      image: "images/portfolio/image6.jpeg"
    },
    {
      title: "Image4",
      image: "images/portfolio/image4.jpg"
    },
    {
      title: "Image8",
      image: "images/portfolio/image7.jpeg"
    },
    {
      title: "Image7",
      image: "images/profilepic.jpeg"
    }
  ],
  work: [
    {
      CompanyName: "Ellucian",
      Specialization:
        "Lead Software Engineer @ Ellucian | Full Stack Developer | AWS | AWS CDK | Node JS | React JS | Feb 2023 - Present"
    },
    {
      CompanyName: "Neosoft Technologies",
      Specialization:
        "Full Stack Engineer | Technical Team Lead | Feb 2020 - Jan 2023"
    },
    {
      CompanyName: "Tata Consultancy Services",
      Specialization: "IT Analyst | Apr 2019 - Feb 2020"
    },
    {
      CompanyName: "Neosoft Technologies",
      Specialization:
        "Full Stack Engineer | Technical Team Lead | May 2016 – Mar 2019"
    },
    {
      CompanyName: "HASCue Technologies",
      Specialization: "Senior Software Developer | Feb 2016 – May 2016"
    },
    {
      CompanyName: "KTECHBEANS SOFTWARE PRIVATE LIMITED",
      Specialization: "Software Developer | Jun 2014 – Jan 2016"
    }
  ],
  skillsDescription: "Your skills here",
  skills: [
    {
      skillname: "HTML5"
    },
    {
      skillname: "CSS"
    },
    {
      skillname: "Reactjs"
    }
  ]
};

export default resumeData;
